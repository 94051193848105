<template>
  <div>
    <div class="flex items-center mb-5">
      <h3 class="uppercase text-lg font-semibold text-primary tracking-wide">
        Calibration History
      </h3>
      <hr class="flex-1 border-primary mx-4" />
      <!-- <button
        class="flex items-center bg-green-200 border hover:bg-green-300 border-green-400 hover:border-green-500 hover:shadow active:shadow-inner transition duration-300 text-secondary rounded-md px-2 py-1"
      >
        <svg
          class="stroke-current h-5 w-5 mr-2"
          fill="none"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
          viewBox="0 0 24 24"
        >
          <path d="M12 5v14M5 12h14" />
        </svg>
        <span>Add Person</span>
      </button> -->
    </div>

    <div class="flex flex-wrap">
      <Table
        v-if="data.length > 0"
        class="w-full"
        :columns="columns"
        :data="data"
        :totalRecords="data.length"
        :hasPagination="false"
        :initialSortOptions="sortOptions"
      />
      <p class="mx-auto my-16" v-if="data.length == 0">
        There is no Calibration History for this Equipment.
      </p>
    </div>
  </div>
</template>

<script>
const Table = () => import("@/components/Table.vue");

export default {
  name: "CalibrationHistory",
  components: {
    Table,
  },
  props: {
    data: Array,
  },
  data() {
    return {
      sortOptions: {
        field: "date",
        isAsc: true,
      },
      columns: [
        {
          title: "Calibration Date",
          field: "date",
          selector: (row) => row.date,
          type: "date",
        },
        {
          title: "Next Calibration Date",
          field: "next_date",
          selector: (row) => row.next_date,
          type: "date",
        },
        {
          title: "Certificate No",
          field: "certificate_number",
          selector: (row) => row.certificate_number,
        },
      ],
    };
  },
};
</script>
